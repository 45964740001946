<template>
    <div>
        <b-row>
            <b-col xs="12" v-if="existingBuilding">
                <h1>Importera - {{building.name}}</h1>
                <router-link :to="{ name: 'manage-building', params: { id: building.id } }">
                    Tillbaka till fastighet
                </router-link>
            </b-col>
            <b-col xs="12" v-else>
                <h1>Importera - Fastigheter</h1>
            </b-col>

        </b-row>
        <b-row>
            <b-col xs="12" md="7" class="my-1">
                <b-form-group id="input-file-label"
                              label="Ladda upp excelfil"
                              label-for="input-file">
                    <b-form-file id="input-file"
                                 v-model="file"                                 
                                 drop-placeholder="Släpp här"
                                 placeholder="Klicka eller dra en excelfil hit"
                                 :disabled="isSubmitting"
                                 @input="onFilesChange"
                                 accept=".xlsx" />
                </b-form-group>

            </b-col>
            <b-col xs="12" md="5" class="my-1">
            </b-col>
        </b-row>


        <!-- Save File - Validation errors -->
        <b-alert :show="validationByKey('SaveFile').length > 0" variant="danger">
            <span>
                Det gick inte ladda upp följande fil{{validationByKey('SaveFile').length > 1 ? 'er' : ''}}
            </span>
            <ul class="text-danger mb-0">
                <li v-for="message in validationByKey('SaveFile')" :key="message">
                    {{ message }}
                </li>
            </ul>
        </b-alert>
        <b-alert :show="errorMessage!=''"
                 variant="danger">
            <label>Fel</label><p>{{errorMessage}}</p>
        </b-alert>
        <b-card v-if="fileId == 0">
            <b-row>
                <b-col xs="12" md="6">
                    <h5>Info</h5>
                    <p>
                        Excelfil i xlsx format och enligt layout.<br />
                        Varje rad blir en position och om Fastighet / Del skiljer sig från föregående så skapas ny. <br />
                        Fastighet / del kan lämnas tomma om rad tillhör samma som föregående rad.<br />
                    </p>
                    <p>
                        Olika status på positioner
                        <ul>
                            <li><b-badge variant="success">OK - Position importeras</b-badge></li>
                            <li><b-badge variant="warning">Varning - Position importeras utan värde som saknas</b-badge></li>
                            <li><b-badge variant="danger">Fel - Position ignoreras</b-badge></li>
                        </ul>

                    </p>
                </b-col>
                <b-col xs="12" md="6">
                    <h5>Layout excelfil</h5>
                    <i>Första raden innehåller rubriker</i>
                    <ol type="A">
                        <li>Fastighet <b-badge variant="warning" v-if="existingBuilding">* Importeras ej</b-badge></li>
                        <li>Adress <b-badge variant="warning" v-if="existingBuilding">* Importeras ej</b-badge></li>
                        <li>Del (Våning)</li>
                        <li>Delnamn</li>
                        <li>Nummer</li>
                        <li>Artikelnr</li>
                        <li>Beskrivning</li>
                        <li>Plats</li>
                        <li>Nästa revision</li>
                        <li>Service (Ja/Nej)</li>
                    </ol>
                </b-col>
            </b-row>
        </b-card>

        <div v-if="fileId > 0">
            <div v-for="building in previewBuildings">
                <h4>
                    <font-awesome-icon class=""
                                       icon="building"
                                       size="sm" /> {{building.name}}    
                <b-badge :variant="building.importStatus">{{building.importStatusText}}</b-badge>
                </h4>
                {{building.address}}

                <div v-for="part in building.parts">
                    <h5>{{part.floor}} - {{part.name}}</h5>
                    <b-table small="true" hover :items="part.positions"
                             :fields="[
                            { key: 'number', label: '#' },
                            { key: 'articleNo', label: 'Artikelnr' },
                            { key: 'articleName', label: 'Artikelnamn' },
                            { key: 'description', label: 'Beskrivning' },
                            { key: 'place', label: 'Plats' },
                             { key: 'serviceNeed', label: 'Service' },
                             { key: 'nextRevisionDate', label: 'Nästa revision' },
                             { key: 'importStatus', label: 'Status' }
]">

                        <template #cell(articleName)="row">
                            <div class="overflow-hidden">
                                {{row.item.articleName}}
                                <img v-if="row.item.fileSymbolId"
                                     style="height: 1.5rem"
                                     :src="getUrl(row.item.fileSymbolId)" />
                            </div>
                        </template>
                        <template #cell(serviceNeed)="row">
                            <input type="checkbox" disabled="disabled" checked v-if="row.item.serviceNeed" />
                        </template>
                        <template #cell(nextRevisionDate)="row">
                            <div class="overflow-hidden">
                                {{ row.item.nextRevisionDate | datetime('yyyy-MM-dd') }}
                            </div>
                        </template>
                        <template #cell(importStatus)="row">
                            <div class="overflow-hidden">
                                <b-badge :variant="row.item.importStatus">{{row.item.importStatusText}}</b-badge>
                            </div>
                        </template>
                    </b-table>
                </div>
            </div>
            <div v-if="!importDone">
                <b-alert show variant="warning" v-if="errorCount > 0">
                    <h4 class="alert-heading">Positioner med problem/fel</h4>
                    <p>
                        Det finns <strong>{{errorCount}}</strong> positioner som innehåller fel, är de <b-badge variant="danger">röda</b-badge> kommer de inte importeras.
                    </p>
                </b-alert>
                <b-row class="mb-4">
                    <b-col xs="12" class="text-center">
                        <hr />
                        <b-button variant="primary"
                                  :disabled="isSubmitting"
                                  class="mr-2"
                                  @click="importFile()">
                            Importera
                        </b-button>
                    </b-col>
                </b-row>
            </div>
            <b-alert variant="success" :show="importDone">
                <h2>Import klar</h2>
                <p v-if="existingBuilding">
                    Delar och positioner skapade...<br />
                    <router-link :to="{ name: 'manage-building', params: { id: building.id } }">
                        Gå till fastighet
                    </router-link>

                </p>
                <p v-else>
                    Fastigheter och positioner skapade... <br />
                    <router-link :to="{ name: 'manage-building-list' }">
                        Gå till fastigheter
                    </router-link>
                </p>
            </b-alert>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import { get, post } from '@/helpers/api';
    import { getDownloadUrl} from '@/helpers/file.helper';
export default {
  name: 'ImportBuildings',
  components: {
  },
  data() {
      return {
        fileId: 0,
        buildingId: parseInt(this.$route.params.id ?? 0),
          file: null,
        isSubmitting: false,
          isBusy: true,
          previewBuildings: [],
          errorMessage: '',
          importDone: false,
          building: {
              id: 0,
              name: '',
              address: '',
              imageAsBase64: null
          }
    };
  },
  computed: {
    ...mapState({
      selectedCustomerId: (state) => state.user.selectedCustomer.id
    }),
      existingBuilding() {
          return this.building.id > 0;
      },
      errorCount() {          
          return this.previewBuildings.map(item => item.positionErrorCount).reduce((prev, next) => prev + next);
      }
  },
  async mounted() {
      if (this.buildingId > 0)
          this.getData();
  },
  methods: {
    async getData() {
          await get('Building', `Get/${this.buildingId}`)
            .then((x) => {
                this.building = x.data;
                this.isLoading = false;
                this.error = null;
            })
            .catch((x) => {
                this.error = x;
                this.isLoading = false;
            });
      },
      async onFilesChange() {
          if (!this.file) return;
          await this.upload();
      },
      async upload() {
          this.validationErrors = null;
          this.isSubmitting = true;
          // Append properties to form.
          let data = new FormData();
           data.append('files', this.file, this.file.name);
              await post(
                  'Import',
                  `Upload?entityId=${this.buildingId}`,
                  data
              )
                  .then((x) => {
                      // this.getSelectedFiles();
                      this.fileId = x.data;
                      this.isSubmitting = false;
                      this.file = null;
                      this.preview();
                  })
                  .catch((x) => {
                      if (x.response.status === 413) {
                          this.validationErrors = { SaveFileSize: ['Filen är för stor'] };
                      } else {
                          this.validationErrors = x.response.data.response.errors;
                          this.allowedExtensions = ['.xlsx'];
                      }
                      this.isSubmitting = false;
                  });
      },
      async preview() {
          this.isSubmitting = true;
          await get('Import', `GetPreview/${this.fileId}?customerId=${this.selectedCustomerId}`)
              .then(x => {
                  this.previewBuildings = x.data;
              })
              .then(() => {
                  this.isSubmitting = false;
                  this.errorMessage = "";
              })
              .catch((x) => {
                  this.isSubmitting = false;
                  this.errorMessage = x.response.data;
                  this.previewBuildings = [];
              });
      },
      async importFile() {
          this.isSubmitting = true;
           const vm = {
               fileId: this.fileId,
               customerId: this.selectedCustomerId,
               buildingId: this.buildingId
           };
           await post('Import', `import/`, vm)
              .then(x => {
                  this.isSubmitting = false;
                  this.importDone = true;
                  this.errorMessage = "";
              })
               .catch((x) => {
                   this.errorMessage = x.response.data;;
              });
      },
       getUrl(id) {
          return getDownloadUrl(id);
      },
      validationByKey(code) {
          return this.validationErrors ? this.validationErrors[code] || [] : [];
      },
  }
};
</script>


